import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4e8cf5c9&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=4e8cf5c9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e8cf5c9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionMain: require('/vercel/path0/src/components/section/main.vue').default,SectionAbout: require('/vercel/path0/src/components/section/about.vue').default,SectionPrograms: require('/vercel/path0/src/components/section/programs.vue').default,SectionMasters: require('/vercel/path0/src/components/section/masters.vue').default,SectionOffers: require('/vercel/path0/src/components/section/offers.vue').default,SectionQuote: require('/vercel/path0/src/components/section/quote.vue').default,SectionRequest: require('/vercel/path0/src/components/section/request.vue').default,BaseFooter: require('/vercel/path0/src/components/base/footer.vue').default})
