
export default {
  name: 'section-quote',
  computed: {
    width () {
      return this.vw();
    },
    height () {
      return this.isMobile ? 300 : this.vh(62);
    },
  },
};
