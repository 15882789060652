
export default {
  name: 'section-about',
  data() {
    return {
      slides: [
        {
          id: 1,
          img: 'images/about/1.webp',
          text: 'Воплощение твоих самых <br>сокровенных фантазий',
        },
        {
          id: 2,
          img: 'images/about/2.webp',
          text: 'Приятная атмосфера и <br>обаятельная компания',
        },
        {
          id: 3,
          img: 'images/about/3.webp',
          text: 'Незабываемые <br>впечатления',
        },
      ],
    };
  },
  computed: {
    /* sizes () {
      return 'xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw';
    }, */
    width () {
      return this.isMobile ? this.vwC() : this.vwC(33.333);
    },
    height () {
      return this.isMobile ? this.width : this.vh(51);
    },
  },
};
