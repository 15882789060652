
export default {
  name: 'section-offers',
  data() {
    return {
      selected: 1,
      offers: [
        'Программа от 1.5 часов по времени <strong>КАЛЬЯН</strong> в подарок',
        'Каждый понедельник дополнение <strong>ВЫСШИЙ ПИЛОТАЖ</strong> в подарок',
        'Каждую пятницу <strong>WELCOME DRINK</strong>', 
        'Программа от 6 000 <strong>ВЕТОЧКА САКУРЫ</strong> в подарок',
        'Программа от 2 часов <strong>ПИП-ШОУ</strong> от мастера',
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.$refs.carousel.showPrev = true;
      this.$refs.carousel.showNext = true;

      this.getPrev().classList.add('offers__prev');
      this.getNext().classList.add('offers__next');
    }, 100);
  },
  methods: {
    getPrev() {
      return document.getElementById(this.$refs.carousel.prevHandler);
    },
    getNext() {
      return document.getElementById(this.$refs.carousel.nextHandler);
    },
    prevOffer() {
      if (this.selected !== 0) this.selected -= 1;
      else this.selected = 4;
      this.getPrev().click();
    },
    nextOffer() {
      if (this.selected < 4) this.selected += 1;
      else this.selected = 0;
      this.getNext().click();
    },
  },
};
