
export default {
  name: 'section-masters',
  data() {
    return {
      masters: [
        'images/masters/1.webp', 
        'images/masters/2.webp', 
        'images/masters/3.webp', 
        'images/masters/4.webp',
      ],
    };
  },
  computed: {
    width () {
      return this.isMobile ? 90 : 141;
    },
    height () {
      return this.isMobile ? 90 : 141;
    },
  },
  methods: {
    goToMastersPage () {
      this.$router.push({ name: 'masters' });
    },
  },
};
