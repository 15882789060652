import { render, staticRenderFns } from "./masters.vue?vue&type=template&id=2d9e4362&"
import script from "./masters.vue?vue&type=script&lang=js&"
export * from "./masters.vue?vue&type=script&lang=js&"
import style0 from "./masters.vue?vue&type=style&index=0&id=2d9e4362&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconArrow: require('/vercel/path0/src/components/icon/arrow.vue').default,BaseButton: require('/vercel/path0/src/components/base/button.js').default,BaseSection: require('/vercel/path0/src/components/base/section.vue').default})
