
import Vue from 'vue';

export default {
  name: 'section-main',
  data() {
    return {
      isStarted: false,
    };
  },
  computed: {
    videoSource() {
      return this.isMobile ? '/video/new/vertical5.webm' : '/video/new/horizontal5.webm';
    },
    videoSourceMp4() {
      return this.isMobile ? '/video/new/vertical5.mp4' : '/video/new/horizontal5.mp4';
    },
  },
  created() {
    this.$nuxt.$on('start', () => {
      this.startVideo();
    });
  },
  mounted() {
    if (Vue.prototype.$navigated) this.startVideo();
  },
  beforeDestroy() {
    this.$nuxt.$off('start');
    this.$nuxt.$off('create');
  },
  methods: {
    startVideo() {
      document.querySelector('.main__video').play();
      this.isStarted = true;
    },
  },
};
