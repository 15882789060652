import { render, staticRenderFns } from "./programs.vue?vue&type=template&id=aa35cd8c&"
import script from "./programs.vue?vue&type=script&lang=js&"
export * from "./programs.vue?vue&type=script&lang=js&"
import style0 from "./programs.vue?vue&type=style&index=0&id=aa35cd8c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseArrow: require('/vercel/path0/src/components/base/arrow.vue').default,BaseButton: require('/vercel/path0/src/components/base/button.js').default,BaseSection: require('/vercel/path0/src/components/base/section.vue').default,IconX: require('/vercel/path0/src/components/icon/icon-x.vue').default,BaseTooltip: require('/vercel/path0/src/components/base/tooltip.vue').default})
