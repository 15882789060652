
export default {
  name: 'base-tooltip',
  props: {
    contentHeight: {
      type: Number,
      default: 40,
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isShowed: false,
    };
  },
};
