
export default {
  name: 'index-page',
  transition: 'fade',
  data() {
    return {
      isMounted: true,
    };
  },
  computed: {
    videoSource() {
      return this.isMobile ? '/video/new/vertical5.webm' : '/video/new/horizontal5.webm';
    },
    videoSourceMp4() {
      return this.isMobile ? '/video/new/vertical5.mp4' : '/video/new/horizontal5.mp4';
    },
  },
  head() {
    return {
      title: 'Geisha | эротический массаж',
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: 'Вакансии | Geisha - эротический масссаж',
        },
        {
          hid: 'description',
          name: 'description',
          content: 'Гейша - мир эротического удовольствия и расслабления, доступный вам прямо здесь, в Сочи. Погрузитесь в океан наслаждения с нашими опытными мастерами массажа',
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'гейша, массаж, сочи, эротический массаж, geisha, erotic massage ',
        },
      ],
    };
  },
};
