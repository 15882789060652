
import programs from '~/constants/programs';
import additions from '~/constants/additions';

export default {
  name: 'section-programs',
  data() {
    return {
      isInfo: false,
      selectedAdds: [],
      images: [
        'images/programs/foreplay.webp',
        'images/programs/pink-flamingo.webp',
        'images/programs/hot-lesbian.webp',
        'images/programs/foot-fetish.webp',
        'images/programs/darkroom-light.webp',
        'images/programs/darkroom-hot.webp',
        'images/programs/for-couples.webp',
        'images/programs/for-girls.webp',
        'images/programs/geisha.webp',
      ],
      selected: 0,
      programs: programs,
      programsDots: programs.map((p, index) => index),
    };
  },
  computed: {
    selectedProgram() {
      return this.programs.find((program) => program.id === this.selected);
    },
    totalPrice() {
      return this.selectedAdds.reduce((total, add) => total + this.additions[add].price, this.selectedProgram.price);
    },
    additions() {
      return additions.filter(item => !this.selectedProgram.exclude.includes(item.value));
    },
    prev() {
      return this.selected - 1;
    },
    next() {
      return this.selected + 1;
    },
    width () {
      return this.isMobile ? 280 : 300;
    },
    height () {
      return this.isMobile ? 400 : 450;
    },
  },
  watch: {
    selected() {
      if (!this.isMobile) this.$refs.carousel.goSlide(this.selected);
    },
    immediate: true,
  },
  methods: {
    fillRequest () {
      this.closeInfo();
      this.$nuxt.$emit('fill-request', {
        program: this.selectedProgram.id,
        adds: this.selectedAdds.map(index => this.additions[index].value),
      });
    },
    isPrev(id) {
      return id === this.prev;
    },
    isNext(id) {
      return id === this.next;
    },
    isSelected(id) {
      return id === this.selected;
    },
    prevProgram() {
      if (this.selected !== 0) this.selected -= 1;
      else this.selected = 8;
      this.selectedAdds = [];
    },
    nextProgram() {
      if (this.selected < 8) {
        this.selected += 1;
      }
      else this.selected = 0;
      this.selectedAdds = [];
    },
    selectProgram (selected) {
      this.$refs.carousel.goSlide(selected);
      this.selectedAdds = [];
    },
    showInfo() {
      this.$set(this, 'isInfo', true);
    },
    closeInfo() {
      this.$set(this, 'isInfo', false);
    },
    addItem(index) {
      const id = this.selectedAdds.findIndex(item => item === index);
      if (id !== -1) this.selectedAdds.splice(id, 1);
      else this.selectedAdds.push(index);
    },
    afterSlideChange (e) {
      this.selected = e;
      this.selectedAdds = [];
    },
  },
};
