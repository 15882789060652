
export default {
  name: 'base-icon-arrow',
  props: {
    width: {
      type: Number,
      default: 15,
    },
    height: {
      type: Number,
      default: 8,
    },
  },
  computed: {
    viewBox() {
      return `0 0 ${this.width} ${this.height}`;
    },
  },
};
